import {Component, Input, ViewChild} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {IAgGridColumnDef, ValueFormatters, GridModule, SwxModule } from "swx.front-end-lib";
import {StationDataRawDataDialogComponent} from "../stationDataGen2/StationDataRawDataDialog.component";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    selector: 'swx-active-frost-emails',
    template: `
        <div style="flex: 0.5 1 auto; display: flex; flex-direction: column; height: 100%">
            <div class="toolbar gridFilters">
                <h3>{{'Emails'|translate}}</h3>
            </div>
            <grid gridId="activeFrostEmails" #grid [resource]="resource" [query]="query" [columnDefs]="columnDefs" [parent]="this"></grid>
        </div>`,
    standalone: true,
    imports: [GridModule, SwxModule]
})
export class ActiveFrostEmailsComponent {
    _query: any;
    @Input() set query(value: any) {
        if (this.grid) this.grid.refresh();
        this._query = value;
    }

    get query() {
        return this._query;
    }

    @ViewChild('grid') grid;
    resource = this.api.ActiveFrostEmail;
    
    constructor(private api: ApiService,
                private formatters: ValueFormatters,
                private dialog: Dialog) {
    }

    columnDefs: IAgGridColumnDef[] = [
        { colId: "EmailInfo.CreatedDate", field: "EmailInfo.CreatedDate", headerName: "Date", width: 90, pinned: 'left', valueFormatter: this.formatters.utcDateFormatter() },
        { colId: "EmailInfo.CreatedTime", field: "EmailInfo.CreatedDate", headerName: "Time", width: 60, excelIgnore: true, pinned: 'left', valueFormatter: this.formatters.utcTimeFormatter() },
        { colId: "EmailInfo.ClientActiveFrostId", field: "EmailInfo.ClientActiveFrostId", headerName: "Client Frost Id", width: 100 },
        { colId: "EmailInfo.Id", field: "EmailInfo.Id", headerName: "Id", width: 90 },
        { colId: "EmailType", field: "EmailType", headerName: "Email Type", width: 240 },
        { colId: "ActiveFrostType", field: "ActiveFrostType", headerName: "AF monitoring Type", width: 140 },
        { colId: "AirportIcaoCode", field: "AirportIcaoCode", headerName: "Airport", width: 80 },
        { colId: "ClientName", field: "ClientName", headerName: "Client", width: 140, editable: false  },
        { colId: "ContactName", field: "ContactName", headerName: "Contact", width: 140, editable: false  },
        { colId: "EmailInfo.Recipient", field: "EmailInfo.Recipient", headerName: "Recipient", width: 220 },
        { colId: "Body", field: "Body", headerName: "Body", cellTemplate: `<a (click)='parent.showEmail(params.data)' class='mdi mdi-eye'></a>`, width: 50 },
        { colId: "AmazonSESStatus", field: "AmazonSESStatus", headerName: "Amazon SES - Status", width: 100, cellTemplate: `<div [style.background-color]="params.data?.AmazonSESStatus === 'Bounce' ? '#ffaaaa' : 'unset'">{{params.data?.AmazonSESStatus}}</div>` },
        { colId: "AmazonSESStatusUpdateDate", field: "AmazonSESStatusUpdateDate", headerName: "Amazon SES - Status updated on", width: 200, valueFormatter: this.formatters.utcDateTimeFormatter(), filterType: 'date' }
    ];

    showEmail(data) {
        const bodyIndex = data.Body?.lastIndexOf("<body>");
        const body = bodyIndex > -1 ? (data.Body?.substring(bodyIndex + 6, data.Body.lastIndexOf("</body>"))) : data.Body;

        this.dialog.open(StationDataRawDataDialogComponent, {
            data: {
                title: data.EmailInfo.Subject,
                html: body,
            },
            width: '1030px',
            height: '680px',
        });
    }
}
