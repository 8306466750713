import {AfterViewInit, Component, Input, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {DiffDialogComponent} from "./DiffDialog.component";
import {ExportApi, IResourceItemList} from "../ApiFactory.service";
import {IAgGridColumnDef} from "../grid/Query.model";
import {ValueFormatters} from "../ValueFormatters.service";
import {EnvironmentConfiguration} from "../EnvironmentConfiguration.model";
import { TranslatePipe } from "../pipes/Translate.pipe";
import { GridComponent } from "../grid/Grid.component";
import { FiltersComponent } from "../grid/Filters.component";
import { FormsModule } from "@angular/forms";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    template: `
<div style="display: flex; width: 100%; height: 100%; flex-direction: column;">
    <form>
        <div class="toolbar">
            <label>
                <span class="caption">{{'Notify me of changes by email'|translate}}</span>
                <input type="checkbox" [checked]="changeAlerts?.length > 0" [disabled]="saving" (click)="toggleChangeAlert()" />
            </label>
            <filters name="query.Filters" [(ngModel)]="query.Filters" [columnDefs]="columnDefs"></filters>
            <button type="button" (click)="grid.refresh()"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
            <button type="button" (click)="grid.export()"><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        </div>
    </form>
    <div style="flex: 1 0 auto; display: flex;">
        <grid #grid gridId="changesDialog" [columnDefs]="columnDefs" [resource]="resource" [headerHeight]="65" [parent]="this" [exportResource]="exportResource" [query]="query"></grid>
    </div>
</div>
`,
    styles: ['button { margin-right: 5px;}'],
    standalone: true,
    imports: [FormsModule, FiltersComponent, GridComponent, TranslatePipe]
})
export class ChangesDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('grid') grid;
    @Input('query') query;
    @Input('changeAlertsQuery') changeAlertsQuery;
    ticketTrackingBaseUrl = this.environment.ticketTrackingBaseUrl;
    columnDefs: IAgGridColumnDef[] = [
        {colId: 'Date', field: 'DateTime', headerName: 'Date', width: 90, valueFormatter: this.formatters.utcDateFormatter(), filterType: 'date'},
        {colId: 'Time', field: 'DateTime', headerName: 'Time', width: 70, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        {colId: 'UserName', field: 'UserName', headerName: 'User', width: 100},
        {colId: 'Type', field: 'Type', headerName: 'Type', width: 90},
        {colId: 'Ticket', field: 'TrackedTicketId', headerName: 'Ticket', width: 90, cellTemplate: '<a *ngIf="params.data?.TrackedTicketId" href="{{parent.ticketTrackingBaseUrl + params.data?.TrackedTicketId}}" target="_blank">{{params.data?.TrackedTicketId}}</a>'},
        {colId: 'Comment', field: 'Comment', headerName: 'Comment', width: 200},
        {colId: 'Diff', field: 'Diff', headerName: 'Diff', width: 60, cellTemplate: '<a *ngIf="params.data?.Diff" (click)="parent.viewDiff(params.data?.Diff)" class="mdi mdi-eye"></a>'}
    ];
    resource = this.api.Change;
    exportResource = this.api.ChangeExport;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private api: ExportApi,
        private dialog: Dialog,
        private formatters: ValueFormatters,
        private environment: EnvironmentConfiguration,
    ) {
    }

    ngOnInit(): void {
        this.changeAlerts = this.api.ChangeAlert.query(this.changeAlertsQuery);
        this.changeAlerts.$promise.then(() => {
            this.saving = false;
        });
    }

    ngAfterViewInit(): void {
        this.grid.gridReadyPromise.then(() => {
            this.grid.refresh();
        });
    }

    changes: IResourceItemList<any>;
    changeAlerts: IResourceItemList<any>;
    saving = true;

    toggleChangeAlert() {
        this.saving = true;
        if (this.changeAlerts.length === 0) {
            const changeAlert = this.api.ChangeAlert.create({
                UserId: this.changeAlertsQuery.UserId,
                SubjectType: this.changeAlertsQuery.SubjectType[0],
                SubjectId: this.changeAlertsQuery.SubjectId,
            });
            changeAlert.$save().then(() => {
                this.changeAlerts = this.api.ChangeAlert.query(this.changeAlertsQuery);
                this.changeAlerts.$promise.then(() => {
                    this.saving = false;
                });
            });
        } else {
            this.api.ChangeAlert.delete(this.changeAlertsQuery).then(() => {
                this.changeAlerts = this.api.ChangeAlert.query(this.changeAlertsQuery);
                this.changeAlerts.$promise.then(() => {
                    this.saving = false;
                });
            });
        }
    }

    viewDiff(diff) {
        this.dialog.open(DiffDialogComponent, {
            width: '700px',
            height: '550px',
            data: {
                diff: JSON.parse(diff)
            }
        })
    }
}
