<swx-dialog>
    <swx-dialog-header></swx-dialog-header>
    <swx-dialog-content>
        <div class="form">
            <label>
                <div style="max-width: 500px; font-family: monospace;">{{metarReading.RawData}}</div>
            </label>
            <label>
                <span class="caption">{{'Date'|translate}}</span>
                <span>{{metarReading.Timestamp|utcDateTimeFormat:dateTimeFormat}}</span>
            </label>
            <label>
                <span class="caption">{{'Type'|translate}}</span>
                <span>{{metarReading.MetarType}}</span>
            </label>
            <label>
                <span class="caption">{{'Weather code'|translate}}</span>
                <span>{{metarReading.WxString}}</span>
            </label>
            <label>
                <span class="caption">{{'Temperature (°C)'|translate}}</span>
                <span>{{metarReading.TempC}}</span>
            </label>
            <label>
                <span class="caption">{{'Visibility (SM)'|translate}}</span>
                <span>{{metarReading.VisibilityStatuteMi}}</span>
            </label>
        </div>
    </swx-dialog-content>
</swx-dialog>
