import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { Base64UploadAdapterPlugin, ChangesService, OrderByPipe, SwxModule } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'BarrelIcingProfileDetail.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgIf, NgFor, CKEditorModule, HasPermissionPipe]
})
export class BarrelIcingProfileDetailComponent {
    item: any;
    returnPath;
    tab;
    @ViewChild('ngForm', {static: true}) ngForm;
    clients = this.api.Client.query();
    client;
    airports = this.api.Airport.query();
    availableAirports: any = [];
    airport;
    equipmentTypes = this.api.EquipmentType.query();
    equipmentType;
    emailFroms = this.api.EmailFrom.query();
    Editor = ClassicEditor;
    ckConfig = {
        extraPlugins: [ Base64UploadAdapterPlugin ],
        toolbar: { items: [ 'bold', 'italic','|', 'link', 'imageUpload', 'heading', 'insertTable', 'bulletedList', 'numberedList', 'outdent', 'indent', '|', 'undo', 'redo' ]}
    };

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private orderByPipe: OrderByPipe,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.BarrelIcingProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.BarrelIcingEquipmentTypes) {
                    this.item.BarrelIcingEquipmentTypes.forEach(related => {
                        delete related.BarrelIcingProfileId;

                        if (related.BarrelIcingEquipmentTypeAirports) {
                            related.BarrelIcingEquipmentTypeAirports.forEach(r2 => {
                                delete r2.BarrelIcingProfileId;
                            });
                        }
                    });
                }
                
                if (this.item.BarrelIcingProfileAirports) {
                    this.item.BarrelIcingProfileAirports.forEach(related => {
                        delete related.BarrelIcingProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.BarrelIcingProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.BarrelIcingProfile.get({ id: id });
        }

        this.item.$promise.then(() => {
            this.updateAvailableAirports();
        });
    }

    save() {
        this.item.$promise.then(item => {
            this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['BarrelIcingProfile'],
            SubjectId: this.item.Id
        });
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }

    updateAvailableAirports() {
        if (this.item.ClientId) {
            if (this.client == null || this.client.Id !== this.item.ClientId) {
                this.client = this.api.Client.get(this.item.ClientId);
            }
            
            this.client.$promise.then(client => {
                this.airports.$promise.then(() => {
                    this.item.BarrelIcingProfileAirports = this.item.BarrelIcingProfileAirports || [];
                    
                    this.availableAirports = this.orderByPipe.transform(this.airports
                        .filter(a => {
                            var clientAirport = client.ClientAirports.find(ca => ca.AirportId === a.Id);
                            
                            if (clientAirport == null
                                || (clientAirport.StationSimulationId == null
                                    && clientAirport.StationGen2Id == null
                                    && clientAirport.StationGen3AId == null
                                    && clientAirport.StationGen3BId == null
                                    && clientAirport.StationGenVId == null)) return false;

                            return this.item.BarrelIcingProfileAirports.every(ca => ca.AirportId !== a.Id);
                        }), 'ICAOCode');
                });
            });
        }
    }

    addBarrelIcingProfileAirport(airport) {
        this.item.BarrelIcingProfileAirports = this.item.BarrelIcingProfileAirports || [];
        this.item.BarrelIcingProfileAirports.push({
            AirportId: airport.Id,
        });
        this.ngForm.form.markAsDirty();
        this.updateAvailableAirports();
    }

    removeBarrelIcingProfileAirport(barrelIcingProfileAirport) {
        this.item.BarrelIcingProfileAirports.splice(this.item.BarrelIcingProfileAirports.indexOf(barrelIcingProfileAirport), 1);
        this.ngForm.form.markAsDirty();
        this.updateAvailableAirports();
    }

    equipmentTypesNotEnabled() {
        return this.equipmentTypes.filter(r => !this.item.BarrelIcingEquipmentTypes.some(x => x.EquipmentTypeId === r.Id));
    }

    addBarrelIcingEquipmentType() {
        this.item.BarrelIcingEquipmentTypes = this.item.BarrelIcingEquipmentTypes || [];
        this.item.BarrelIcingEquipmentTypes.push({
            EquipmentTypeId: this.equipmentType.Id
        });
        this.equipmentType = null;
        this.ngForm.form.markAsDirty();
    };

    removeBarrelIcingEquipmentType(barrelIcingIcingEquipmentType) {
        this.item.BarrelIcingEquipmentTypes.splice(this.item.BarrelIcingEquipmentTypes.indexOf(barrelIcingIcingEquipmentType), 1);
        this.ngForm.form.markAsDirty();
    };

    isBarrelIcingEquipmentTypeAirportEnabled(barrelIcingEquipmentType, airportId) {
        if (!barrelIcingEquipmentType.BarrelIcingEquipmentTypeAirports) return false;
        var found = false;
        barrelIcingEquipmentType.BarrelIcingEquipmentTypeAirports.forEach((barrelIcingEquipmentTypeAirport) => {
            if (barrelIcingEquipmentTypeAirport.AirportId === airportId) {
                found = true;
                return false;
            }
            return true;
        });
        return found;
    }

    toggleBarrelIcingEquipmentTypeAirport(barrelIcingEquipmentType, airportId) {
        barrelIcingEquipmentType.BarrelIcingEquipmentTypeAirports = barrelIcingEquipmentType.BarrelIcingEquipmentTypeAirports || [];
        var found = false;
        barrelIcingEquipmentType.BarrelIcingEquipmentTypeAirports.forEach((barrelIcingEquipmentTypeAirport, index) => {
            if (barrelIcingEquipmentTypeAirport.AirportId === airportId) {
                barrelIcingEquipmentType.BarrelIcingEquipmentTypeAirports.splice(index, 1);
                found = true;
                return false;
            }
            return true;
        });

        if (!found) {
            barrelIcingEquipmentType.BarrelIcingEquipmentTypeAirports.push({ AirportId: airportId, EquipmentTypeId: barrelIcingEquipmentType.EquipmentTypeId });
        }

        this.ngForm.form.markAsDirty();
    }
}
