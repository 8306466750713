import {Component, Inject, OnInit} from "@angular/core";
import {RootScope} from "../shared/RootScope.service";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent, SwxModule} from "swx.front-end-lib";
import {DIALOG_DATA} from "@angular/cdk/dialog";

@Component({
    templateUrl: './MetarReadingDialog.component.html',
    standalone: true,
    imports: [SwxModule, DialogComponent, DialogHeaderComponent, DialogContentComponent]
})
export class MetarReadingDialogComponent implements OnInit {
    dateTimeFormat = this.root.dateTimeFormat;
    airport!: any;
    title!: string;
    metarReading!: any;
    
    constructor(@Inject(DIALOG_DATA) private data: { airport: any; title: string; metarReading: any }, private root: RootScope) {
    }
    
    ngOnInit() {
        this.airport = this.data.airport;
        this.title = this.data.title;
        this.metarReading = this.data.metarReading;
    }
}
