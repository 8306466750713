import {Component, Inject, OnInit} from "@angular/core";
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {DialogComponent, DialogContentComponent, DialogHeaderComponent} from "swx.front-end-lib";

@Component({
    templateUrl: 'StationDataRawDataDialog.component.html',
    standalone: true,
    imports: [
        DialogComponent, DialogHeaderComponent, DialogContentComponent
    ]
})
export class StationDataRawDataDialogComponent implements OnInit {
    rawData: string;
    html: string;
    title!: string;
    
    constructor(@Inject(DIALOG_DATA) private data: { rawData?: string, html?: string; title?: string; }) {
    }
    
    ngOnInit() {
        this.title = this.data.title || "Raw Data";
        this.rawData = this.data.rawData;
        this.html = this.data.html;
    }
}
