<ul>
    <li>
        <h3>{{'Data'|translate}}</h3>
        <ul>
            <li><a [routerLink]="['/hotData']" routerLinkActive="active" *ngIf="'DataHOTs'|hasPermission">{{'HOTs'|translate}}</a></li>
            <li><a [routerLink]="['/hotMap']" routerLinkActive="active" *ngIf="'DataHOTs'|hasPermission">{{'HOT request map'|translate}}</a></li>
            <li><a [routerLink]="['/stationDataGen2']" routerLinkActive="active" *ngIf="'DataGen2'|hasPermission">{{'Gen 2'|translate}}</a></li>
            <li><a [routerLink]="['/stationDataGen3']" routerLinkActive="active" *ngIf="'DataGen3'|hasPermission">{{'Gen 3'|translate}}</a></li>
            <li><a [routerLink]="['/stationDataGenV']" routerLinkActive="active" *ngIf="'DataGenV'|hasPermission">{{'Gen V'|translate}}</a></li>
            <li><a [routerLink]="['/weatherProfileData']" routerLinkActive="active" *ngIf="'DataWeatherProfiles'|hasPermission">{{'Weather profiles'|translate}}</a></li>
            <li><a [routerLink]="['/metarReadings']" routerLinkActive="active" *ngIf="'DataMETAR'|hasPermission">{{'METAR'|translate}}</a></li>
            <li><a [routerLink]="['/barrelIcing']" routerLinkActive="active" *ngIf="'DataBarrelIcingWarnings'|hasPermission">{{'Barrel icing warnings'|translate}}</a></li>
            <li><a [routerLink]="['/activeFrost']" routerLinkActive="active" *ngIf="'DataActiveFrosts'|hasPermission">{{'Active frost warnings'|translate}}</a></li>
            <li><a [routerLink]="['/noaaRapReports']" routerLinkActive="active" *ngIf="'DataNoaaRapReports'|hasPermission">{{'NOAA RAP reports'|translate}}</a></li>
            <li><a [routerLink]="['/engineCoverAlerts']" routerLinkActive="active" *ngIf="'DataEngineCoverAlerts'|hasPermission">{{'Engine cover alerts'|translate}}</a></li>
            <li><a [routerLink]="['/noaaWpcForecasts']" routerLinkActive="active" *ngIf="'DataNoaaWpcForecasts'|hasPermission">{{'NOAA WPC forecasts'|translate}}</a></li>
            <li><a [routerLink]="['/openMeteoForecasts']" routerLinkActive="active" *ngIf="'DataOpenMeteoForecasts'|hasPermission">{{'Open-Meteo forecasts'|translate}}</a></li>
            <li><a [routerLink]="['/noaaWpcAlert']" routerLinkActive="active" *ngIf="'DataNoaaWpcAlerts'|hasPermission">{{'NOAA WPC alerts'|translate}}</a></li>
            <li><a [routerLink]="['/stationAlertEmails']" routerLinkActive="active" *ngIf="'DataStationAlertEmails'|hasPermission">{{'Station alert emails'|translate}}</a></li>
            <li><a [routerLink]="['/humanObservationData']" routerLinkActive="active" *ngIf="'DataHumanObservations'|hasPermission">{{'Human observations'|translate}}</a></li>
            <li><a [routerLink]="['/portalUserLogins']" routerLinkActive="active" *ngIf="'DataPortalLogins'|hasPermission">{{'Portal logins'|translate}}</a></li>
            <li><a [routerLink]="['/acarsOooiReports']" routerLinkActive="active" *ngIf="'DataAcarsOooiReports'|hasPermission">{{'ACARS OOOI reports'|translate}}</a></li>
            <li><a [routerLink]="['/flairOooiReports']" routerLinkActive="active" *ngIf="'DataFlairOooiReports'|hasPermission">{{'Flair OOOI reports'|translate}}</a></li>
            <li><a [routerLink]="['/aidxEvents']" routerLinkActive="active" *ngIf="'DataAidxEvents'|hasPermission">{{'AIDX events'|translate}}</a></li>
            <li><a [routerLink]="['/flightAwareFirehoseMessages']" routerLinkActive="active" *ngIf="'DataFlightAwareFirehoseMessages'|hasPermission">{{'FlightAware Firehose'|translate}}</a></li>
            <li><a [routerLink]="['/finnairInits']" routerLinkActive="active" *ngIf="'DataFinnairInits'|hasPermission">{{'Finnair inits'|translate}}</a></li>
            <li><a [routerLink]="['/sureForecastAllClears']" routerLinkActive="active" *ngIf="'DataSureForecastAllClear'|hasPermission">{{'SureForecast AllClear'|translate}}</a></li>
            <li><a [routerLink]="['/nowcastsLwe12']" routerLinkActive="active" *ngIf="'DataNowcastsLWE12'|hasPermission">{{'Nowcasts LWE12'|translate}}</a></li>
            <li><a [routerLink]="['/nowcastsLwe30']" routerLinkActive="active" *ngIf="'DataNowcastsLWE30'|hasPermission">{{'Nowcasts LWE30'|translate}}</a></li>
            <li><a [routerLink]="['/deicingReports']" routerLinkActive="active" *ngIf="'DataDeicingReports'|hasPermission">{{'Deicing reports'|translate}}</a></li>
            <li><a [routerLink]="['/deicingRequests']" routerLinkActive="active" *ngIf="'DataDeicingRequests'|hasPermission">{{'Deicing requests'|translate}}</a></li>
            <li><a [routerLink]="['/acarsIncomingMessages']" routerLinkActive="active" *ngIf="'DataACARSReceived'|hasPermission">{{'ACARS received'|translate}}</a></li>
            <li><a [routerLink]="['/acarsOutgoingMessages']" routerLinkActive="active" *ngIf="'DataACARSSent'|hasPermission">{{'ACARS sent'|translate}}</a></li>
            <li><a [routerLink]="['/flights']" routerLinkActive="active" *ngIf="'DataFlights'|hasPermission">{{'Flight tracker'|translate}}</a></li>
            <li><a [routerLink]="['/heatFlapsAlerts']" routerLinkActive="active" *ngIf="'DataHeatFlapsAlerts'|hasPermission">{{'Heat flaps alerts'|translate}}</a></li>
            <li><a [routerLink]="['/flapContaminationAlerts']" routerLinkActive="active" *ngIf="'DataFlapContaminationAlerts'|hasPermission">{{'Flap contamination alerts'|translate}}</a></li>
            <li><a [routerLink]="['/freezingFogAlerts']" routerLinkActive="active" *ngIf="'DataFreezingFogAlerts'|hasPermission">{{'FZFG alerts'|translate}}</a></li>
            <li><a [routerLink]="['/stationMonitoringAlerts']" routerLinkActive="active" *ngIf="'DataMonitoringAlerts'|hasPermission">{{'Station monitoring alerts'|translate}}</a></li>
            <li><a [routerLink]="['/weatherAlerts']" routerLinkActive="active" *ngIf="'DataWeatherAlerts'|hasPermission">{{'Weather alerts'|translate}}</a></li>
            <li><a [routerLink]="['/yvrWeatherHots']" routerLinkActive="active" *ngIf="'DataYvrWeatherHots'|hasPermission">{{'YVR weather HOTs'|translate}}</a></li>
            <li><a href="{{environment?.ServiceMonitoringFrontEndNgUrl}}" target="_blank" *ngIf="'DataServiceStatus'|hasPermission">{{'Service status'|translate}} <span class="mdi mdi-open-in-new"></span></a></li>
            <li><a routerLink="/stationStatus" routerLinkActive="active" *ngIf="'DataStationStatus'|hasPermission">{{'Station status'|translate}}</a></li>
            <li><a routerLink="/stationPerformance" routerLinkActive="active" *ngIf="'DataStationStatus'|hasPermission">{{'Station performance'|translate}}</a></li>
            <li><a routerLink="/algorithmPerformance" routerLinkActive="active" *ngIf="'DataStationStatus'|hasPermission">{{'Algorithm performance'|translate}}</a></li>
            <li><a routerLink="/changes" routerLinkActive="active" *ngIf="'DataChanges'|hasPermission">{{'Changes'|translate}}</a></li>
            <li><a routerLink="/loginHistory" routerLinkActive="active" *ngIf="'DataLogins' | hasPermission">{{'Login history'|translate}}</a></li>
        </ul>
    </li>
    <li *ngIf="currentUser?.Permissions.indexOf('Config') > -1">
        <h3>{{'Configuration'|translate}}</h3>
        <ul>
            <li><a [routerLink]="['/clients']" *ngIf="'ConfigViewClients'|hasPermission">{{'Clients'|translate}}</a></li>
            <li><a [routerLink]="['/airports']" routerLinkActive="active" *ngIf="'ConfigViewAirports'|hasPermission">{{'Airports'|translate}}</a></li>
            <li><a [routerLink]="['/fluids']" routerLinkActive="active" *ngIf="'ConfigViewFluids'|hasPermission">{{'Fluids'|translate}}</a></li>
            <li><a [routerLink]="['/equipmentTypes']" routerLinkActive="active" *ngIf="'ConfigViewEquipmentTypes'|hasPermission">{{'Equipment types'|translate}}</a></li>
            <li><a [routerLink]="['/users']" routerLinkActive="active" *ngIf="'ConfigViewUsers'|hasPermission">{{'Users'|translate}}</a></li>
            <li><a [routerLink]="['/ibmMqServerConfigurations']" routerLinkActive="active" *ngIf="'ConfigViewIBMMQServerConfigurations'|hasPermission">{{'IBM MQ'|translate}}</a></li>
            <li><a [routerLink]="['/imapServerConfigurations']" routerLinkActive="active" *ngIf="'ConfigViewIMAPServerConfigurations'|hasPermission">{{'IMAP servers'|translate}}</a></li>
            <li><a [routerLink]="['/jmsServerConfigurations']" routerLinkActive="active" *ngIf="'ConfigViewJMSServerConfigurations'|hasPermission">{{'JMS servers'|translate}}</a></li>
            <li><a [routerLink]="['/sqsQueueConfigurations']" routerLinkActive="active" *ngIf="'ConfigViewSQSQueueConfigurations'|hasPermission">{{'SQS queues'|translate}}</a></li>
            <li><a [routerLink]="['/sftpServerConfigurations']" routerLinkActive="active" *ngIf="'ConfigViewSFTPServerConfigurations'|hasPermission">{{'SFTP servers'|translate}}</a></li>
            <li><a [routerLink]="['/hotConditions']" routerLinkActive="active" *ngIf="'ConfigViewHotConditions'|hasPermission">{{'HOT conditions for dispatch portal'|translate}}</a></li>
            <li><a [routerLink]="['/holdoverTaxiTimePredictions']" routerLinkActive="active" *ngIf="'ConfigViewClientsHoldoverTaxiTimePrediction'|hasPermission">{{'Holdover taxi time prediction'|translate}}</a></li>
            <li>
                <h3>{{'Alerts'|translate}}</h3>
                <ul>
                    <li><a routerLink="/heatFlapsAlertProfiles" routerLinkActive="active" *ngIf="'ConfigViewHeatFlapsAlertProfiles' | hasPermission">{{'Heat flaps alerts'|translate}}</a></li>
                    <li><a routerLink="/flapContaminationAlertProfiles" routerLinkActive="active" *ngIf="'ConfigViewFlapContaminationAlertProfiles' | hasPermission">{{'Flap contamination alerts'|translate}}</a></li>
                    <li><a routerLink="/freezingFogAlertProfiles" routerLinkActive="active" *ngIf="'ConfigViewFreezingFogAlertProfiles' | hasPermission">{{'FZFG alerts'|translate}}</a></li>
                    <li><a routerLink="/engineCoverAlertProfiles" routerLinkActive="active" *ngIf="('ConfigViewEngineCoverAlertProfiles'|hasPermission)">{{'Engine cover alerts'|translate}}</a></li>
                    <li><a routerLink="/barrelIcingProfiles" routerLinkActive="active" *ngIf="('ConfigViewBarrelIcingProfiles'|hasPermission)">{{'Barrel icing warnings'|translate}}</a></li>
                    <li><a routerLink="/activeFrostProfiles" routerLinkActive="active" *ngIf="('ConfigViewActiveFrostProfiles'|hasPermission)">{{'Active frost warnings'|translate}}</a></li>
                    <li><a routerLink="/weatherAlertProfiles" routerLinkActive="active" *ngIf="('ConfigViewWeatherAlertProfiles'|hasPermission)">{{'Weather alerts'|translate}}</a></li>
                </ul>
            </li>
            <li>
                <h3>{{'LWE'|translate}}</h3>
                <ul>
                    <li><a routerLink="/lweMessageMappingProfiles" routerLinkActive="active" *ngIf="'ConfigViewLWEMessageMappings'|hasPermission">{{'Client message mappings'|translate}}</a></li>
                    <li><a routerLink="/stations" routerLinkActive="active" *ngIf="'ConfigViewStations'|hasPermission">{{'Stations'|translate}}</a></li>
                    <li><a routerLink="/sensorSelectMatrices" routerLinkActive="active" *ngIf="'ConfigViewStations'|hasPermission">{{'Sensor select matrices'|translate}}</a></li>
                    <li><a routerLink="/remoteEndpoints" routerLinkActive="active" *ngIf="'ConfigViewRemoteEndpoints'|hasPermission">{{'Remote endpoints'|translate}}</a></li>
                    <li><a routerLink="/mqttBrokers" routerLinkActive="active" *ngIf="'ConfigViewMQTTBrokers'|hasPermission">{{'MQTT brokers'|translate}}</a></li>
                    <li><a routerLink="/stationProblemConfigurations" routerLinkActive="active" *ngIf="'ConfigViewStations'|hasPermission">{{'Station alarms'|translate}}</a></li>
                    <li><a routerLink="/stationMonitoringProfiles" routerLinkActive="active" *ngIf="'ConfigViewStationMonitoringProfiles' | hasPermission">{{'Station monitoring profiles'|translate}}</a></li>
                    <li><a routerLink="/lweEquationProfiles" routerLinkActive="active" *ngIf="'ConfigViewLWEEquations'|hasPermission">{{'LWE equations'|translate}}</a></li>
                    <li><a routerLink="/weatherTypeCountProfiles" routerLinkActive="active" *ngIf="'ConfigViewWeatherTypeCounts'|hasPermission">{{'Most recent weather type counts'|translate}}</a></li>
                    <li><a routerLink="/weatherTypeNgToCategoryMappingProfiles" routerLinkActive="active" *ngIf="'ConfigViewWeatherTypeNgToCategoryMappingProfiles'|hasPermission">{{'Weather type NG to category mappings'|translate}}</a></li>
                    <li><a routerLink="/instantWeatherTypeRuleProfiles" routerLinkActive="active" *ngIf="'ConfigViewInstantWeatherTypeRuleProfiles'|hasPermission">{{'Instant weather type rules'|translate}}</a></li>
                    <li><a routerLink="/weatherTypeMappingProfiles" routerLinkActive="active" *ngIf="'ConfigViewWMOCodeMappings'|hasPermission">{{'WMO code mappings'|translate}}</a></li>
                    <li><a routerLink="/thiesLPMWMOCodeMappingProfiles" routerLinkActive="active" *ngIf="'ConfigViewThiesLPMWMOCodeMappingProfiles'|hasPermission">{{'Thies LPM WMO code remappings'|translate}}</a></li>
                    <li><a routerLink="/stationAlertTemplates" routerLinkActive="active" *ngIf="('ConfigViewStationAlertTemplate'|hasPermission)">{{'Station alert templates'|translate}}</a></li>
                    <li><a routerLink="/sartorius" routerLinkActive="active" *ngIf="('ConfigViewGen2'|hasPermission)">{{'Sartorius'|translate}}</a></li>
                </ul>
            </li>
            <li>
                <h3>{{'METAR'|translate}}</h3>
                <ul>
                    <li><a routerLink="/metarMessageMappingProfiles" routerLinkActive="active" *ngIf="('ConfigViewMETARMessageMappingProfiles'|hasPermission)">{{'Client message mappings'|translate}}</a></li>
                    <li>
                        <a routerLink="/metarWeatherMappingProfiles" routerLinkActive="active" *ngIf="('ConfigViewMETARWeatherMappingProfiles'|hasPermission)">{{'Client weather configurations'|translate}}</a>
                        <ul>
                            <li><a routerLink="/metarWeatherCodeMappingProfiles" routerLinkActive="active" *ngIf="('ConfigViewMETARWeatherCodeMappingProfiles'|hasPermission)">{{'Single weather code mappings'|translate}}</a></li>
                            <li><a routerLink="/metarWeatherTypeMappingProfiles" routerLinkActive="active" *ngIf="('ConfigViewMETARWeatherTypeMappingProfiles'|hasPermission)">{{'Weather combinations'|translate}}</a></li>
                            <li><a routerLink="/metarWeatherCodeRemappingProfiles" routerLinkActive="active" *ngIf="('ConfigViewMETARWeatherCodeRemappingProfiles'|hasPermission)">{{'RegEx remappings'|translate}}</a></li>
                            <li><a routerLink="/metarMixedPrecipitationProfiles" routerLinkActive="active" *ngIf="('ConfigViewMetarMixedPrecipitationProfiles'|hasPermission)">{{'Mixed precip profiles'|translate}}</a></li>
                        </ul>
                    </li>
                    <li><a routerLink="/metarSettings" routerLinkActive="active" *ngIf="('METARSettings'|hasPermission)">{{'Basic settings'|translate}}</a></li>
                </ul>
            </li>
        </ul>
    </li>
    <li *ngIf="currentUser?.Permissions.indexOf('Mobile') > -1">
        <h3>{{'Mobile'|translate}}</h3>
        <ul>
            <li><a [routerLink]="['/clientApiTokens']" *ngIf="'ConfigViewClientApiTokens'|hasPermission">{{'App configurations'|translate}}</a></li>
            <li><a [routerLink]="['/mobileDocuments']" *ngIf="'ConfigViewMobileDocumentProfiles'|hasPermission">{{'Documents'|translate}}</a></li>
            <li><a routerLink="/mobileWeatherMappingProfiles" routerLinkActive="active" *ngIf="'ConfigViewMobileWeatherMappingProfiles'|hasPermission">{{'Weather menus'|translate}}</a></li>
            <li><a routerLink="/mobileApps" routerLinkActive="active" *ngIf="'ConfigViewMobileApps'|hasPermission">{{'Apps'|translate}}</a></li>
        </ul>
    </li>
    <li>
        <h3>{{'Validation'|translate}}</h3>
        <ul>
            <li><a [routerLink]="['/metarValidation']" routerLinkActive="active" *ngIf="'ValidationMETAR'|hasPermission">{{'METAR'|translate}}</a></li>
            <li><a [routerLink]="['/gen2Validation']" routerLinkActive="active" *ngIf="'ValidationGen2'|hasPermission">{{'Gen 2'|translate}}</a></li>
            <li><a [routerLink]="['/gen3Validation']" routerLinkActive="active" *ngIf="'ValidationGen3'|hasPermission">{{'Gen 3'|translate}}</a></li>
        </ul>
    </li>
    <li>
        <h3>{{'Reports'|translate}}</h3>
        <ul>
            <li><a [routerLink]="['/reportHumanObservationsWithWeatherProfiles']" routerLinkActive="active" *ngIf="'ReportHumanObservationsWithWeatherProfiles'|hasPermission">{{'Human observations with weather profiles'|translate}}</a></li>
            <li><a [routerLink]="['/reportDeicing']" routerLinkActive="active" *ngIf="'ReportDeicing'|hasPermission">{{'Deicing'|translate}}</a></li>
            <li><a [routerLink]="['/reportMetarWeatherTypes']" routerLinkActive="active" *ngIf="'ReportMetarWeatherTypes'|hasPermission">{{'METAR weather types'|translate}}</a></li>
            <li><a [routerLink]="['/reportHOTs']" routerLinkActive="active" *ngIf="'ReportHOTs'|hasPermission">{{'HOTs'|translate}}</a></li>
        </ul>
    </li>
    <li>
        <h3>{{'Upload'|translate}}</h3>
        <ul>
            <li><a [routerLink]="['/metarReadingDataUpload']" routerLinkActive="active">{{'METAR readings'|translate}}</a></li>
            <li><a [routerLink]="['/humanObservationDataUpload']" routerLinkActive="active">{{'Human observations'|translate}}</a></li>
        </ul>
    </li>
    <li>
        <h3>{{'Tools'|translate}}</h3>
        <ul>
            <li><a [routerLink]="['/weatherProfileRerun']" routerLinkActive="active">{{'Weather profile rerun'|translate}}</a></li>
            <li><a [routerLink]="['/stationReadingImport']" routerLinkActive="active">{{'Station reading import'|translate}}</a></li>
        </ul>
    </li>
    <li *ngIf="'Debug'|hasPermission">
        <h3>{{'Debug'|translate}}</h3>
        <ul>
            <li><a [routerLink]="['/weatherProfiler']" routerLinkActive="active">{{'Weather profiler'|translate}}</a></li>
            <li><a [routerLink]="['/hotCalculator']" routerLinkActive="active">{{'Hot calculator'|translate}}</a></li>
            <li><a [routerLink]="['/hotRequest']" routerLinkActive="active">{{'Hot request'|translate}}</a></li>
            <li><a [routerLink]="['/hotSimulator']" routerLinkActive="active">{{'HOT simulator'|translate}}</a></li>
            <li><a [routerLink]="['/manualSensorEntry']" routerLinkActive="active">{{'Sensor decoder'|translate}}</a></li>
            <li><a [routerLink]="['/manualStationEntry']" routerLinkActive="active">{{'Station decoder'|translate}}</a></li>
            <li><a [routerLink]="['/metarFinalWeatherType']" routerLinkActive="active">{{'METAR decoder'|translate}}</a></li>
            <li><a [routerLink]="['/log']" routerLinkActive="active">{{'Log'|translate}}</a></li>
            <li><a [routerLink]="['/clientApi']" routerLinkActive="active">{{'Client API'|translate}}</a></li>
            <li><a [routerLink]="['/weatherApi']" routerLinkActive="active">{{'Weather API'|translate}}</a></li>
            <li><a [routerLink]="['/acarsRequest']" routerLinkActive="active">{{'ACARS request'|translate}}</a></li>
            <li><a [routerLink]="['/xmlRequest']" routerLinkActive="active">{{'XML request'|translate}}</a></li>
            <li><a [routerLink]="['/ibmmqPutTest']" routerLinkActive="active">{{'IBM MQ Put'|translate}}</a></li>
            <li><a [routerLink]="['/eventTest']" routerLinkActive="active">{{'Event test'|translate}}</a></li>
            <li><a [routerLink]="['/voiceCallerTest']" routerLinkActive="active">{{'Voice call test'|translate}}</a></li>
        </ul>

    </li>
</ul>
