import {Component, Input, ViewEncapsulation, OnDestroy, ViewContainerRef} from '@angular/core';
import { GridApi, GridReadyEvent, GridOptions } from 'ag-grid-community';
import { QuerySerializerService } from "../QuerySerializer.service";
import { ExportApi, IResource, IResourceItem } from "../ApiFactory.service";
import { ColumnPreferencesService } from "./ColumnPreferences.service";
import { ExportDialogComponent } from './exportDialog/ExportDialog.component';
import { FilterPipe } from "../pipes/Filter.pipe";
import {ColumnPreferenceGroup, IAgGridColGroupDef, IAgGridColumnDef, IBaseQuery} from "./Query.model";
import { CellTemplateService } from "./CellTemplate.service";
import {ColumnSelectionDialogComponent} from "./columnSelectionDialog/ColumnSelectionDialog.component";
import { AgGridModule } from 'ag-grid-angular';
import {Dialog, DialogRef} from "@angular/cdk/dialog";
import {Subscription} from "rxjs";

export interface IConfigGridBaseQuery extends IBaseQuery {
    includeInactive?: boolean;
}

export interface IConfigResourceItem extends IResourceItem {
    Active?: boolean;
}

@Component({
    selector: 'config-grid[columnDefs]',
    templateUrl: 'ConfigGrid.component.html',
    styleUrls: ['./Grid.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [AgGridModule],
})
export class ConfigGridComponent implements OnDestroy {
    @Input() gridId: string;
    private componentRefs;
    protected _columnDefs;
    @Input() set columnDefs(columnDefs: IAgGridColumnDef[] | IAgGridColGroupDef[]) {
        if (this.parent != null) {
            this.componentRefs = this.cellTemplateService.transformColumnDefCellTemplates(this.parent, this.viewContainerRef, columnDefs);
        }
        if (this.gridId) {
            let columnPreferences = this.columnPreferencesService.loadColumnPreferences(this.gridId + '_selected', columnDefs);
            columnPreferences.forEach(g => {
                g.children.forEach(c => {
                    c.hide = !c.selected && c.alwaysSelected != true;
                });
            });
            this._columnDefs = columnPreferences;
        } else {
            this._columnDefs = columnDefs;
        }
    }
    get columnDefs(): any {
        return this._columnDefs;
    }
    @Input('gridOptions') extraGridOptions: GridOptions = {};
    @Input() rowSelection: string = '';
    @Input() headerHeight = 45;
    @Input() rowHeight = 30;
    @Input() components: any = {};
    @Input() query: IConfigGridBaseQuery = {};
    @Input() resource: IResource<any>;
    @Input() exportResource: IResource<any>;
    @Input() exportGet: boolean = false;
    private _allRows: IConfigResourceItem[];
    @Input() set allRows(rows: IConfigResourceItem[]) {
        this._allRows = rows || [];
        this.updateFilteredRows();
    }
    get allRows(): any {
        return this._allRows;
    }
    @Input() rowData: IConfigResourceItem[];
    private _parent: any;
    @Input()
    set parent(parent: any) {
        this._parent = parent;
        if (this._columnDefs != null) {
            this.componentRefs = this.cellTemplateService.transformColumnDefCellTemplates(this.parent, this.viewContainerRef, this._columnDefs);
        }
    }
    get parent(): any {
        return this._parent;
    }
    private _searchQuery: string;
    @Input() set searchQuery(searchQuery: string) {
        this._searchQuery = searchQuery;
        this.updateFilteredRows();
    }
    get searchQuery(): any {
        return this._searchQuery;
    }
    private _includeInactive = false;
    @Input() set includeInactive(includeInactive: boolean) {
        this._includeInactive = includeInactive;
        this.updateFilteredRows();
    }
    get includeInactive(): any {
        return this._includeInactive;
    }
    gridApi: GridApi;
    get gridOptions(): GridOptions {
        return {
            suppressPropertyNamesCheck: true,
            defaultColDef: {
                sortable: true,
                resizable: true,
            },
            ...this.extraGridOptions,
        };
    };
    gridReadyPromise: Promise<void>;
    private gridReadyResolve;

    private columnSelectionDialogRef!: DialogRef<ColumnPreferenceGroup[]>;
    private columnSelectionDialogClosedSub!: Subscription;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private cellTemplateService: CellTemplateService,
        private querySerializerService: QuerySerializerService,
        private columnPreferencesService: ColumnPreferencesService,
        private api: ExportApi,
        private dialog: Dialog,
    ) {
        this.gridReadyPromise = new Promise<void>(resolve => {
            this.gridReadyResolve = resolve;
        })
    }

    ngOnDestroy() {
        if (this.componentRefs != null) {
            this.cellTemplateService.cleanup(this.componentRefs);
        }

        if (this.columnSelectionDialogClosedSub) {
            this.columnSelectionDialogClosedSub.unsubscribe();
        }
    }

    onGridReady({ api } : GridReadyEvent) {
        this.gridApi = api;
        this.updateFilteredRows();
        this.gridReadyResolve();
    }

    export(extraQueryParams?: any) {
        this.dialog.open(ExportDialogComponent, {
            width: '640px',
            height: '320px',
            data: {
                columnPreferencesStorageKey: this.gridId,
                api: this.api,
                exportResource: this.exportResource,
                exportGet: this.exportGet,
                query: {
                    ...this.query,
                    ...extraQueryParams,
                    IncludeInactive: this.includeInactive,
                },
                columnDefs: this._columnDefs,
            }
        })
    }

    selectColumns() {
        this.columnSelectionDialogRef = this.dialog.open(ColumnSelectionDialogComponent, {
            width: '640px',
            height: '640px',
            data: {
                columnPreferencesStorageKey: this.gridId,
                columnDefs: this._columnDefs,
            }
        })

        this.columnSelectionDialogClosedSub = this.columnSelectionDialogRef.closed
            .subscribe(columnDefs => {
                if (columnDefs) {
                    this.columnDefs = columnDefs;
                }
            })
    }

    updateFilteredRows() {
        if (!this._allRows && this.rowData != null) {
            this.gridApi?.setRowData(this.rowData)
            return;
        }
        
        var rows = this._includeInactive ? this._allRows : this._allRows?.filter(r => r.Active);

        if (this._searchQuery) {
            rows = FilterPipe.instance.transform(rows, this._searchQuery);
        }

        this.gridApi?.setRowData(rows)
    }

    refresh(): Promise<void> {
        const self = this;
        return new Promise((resolve) => {
            this.gridReadyPromise.then(() => {
                self.gridApi.showLoadingOverlay();

                const queryParams = self.querySerializerService.toHttpParams({
                    ...self.query,
                    includeInactive: true,
                });

                self.resource
                    .query(queryParams)
                    .$promise
                    .then(response => {
                        this.allRows = response;
                        self.gridApi.hideOverlay();
                        resolve(undefined);
                    });
            });
        });
    }
}
