import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {IAgGridColGroupDef, StorageService, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import {StationDataRawDataDialogComponent} from "../stationDataGen2/StationDataRawDataDialog.component";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    templateUrl: './StationAlertEmail.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class StationAlertEmailComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'stationAlertEmailQuery';
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    clients = this.api.Client.query();
    stations = this.api.Station.query();
    resource = this.api.StationAlertEmail;
    exportResourcePost = this.api.StationAlertEmailExport;

    constructor(private api: ApiService,
                private storage: StorageService,
                private formatters: ValueFormatters,
                private dialog: Dialog,
    ) {
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery) {
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    }

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }

    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: 'Station alert email',
            children: [
                { colId: "Date", field: "CreatedDate", headerName: "Date",  width: 90, pinned: 'left', filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "Time", field: "CreatedDate", headerName: "Time",  width: 60, excelIgnore: true, pinned: 'left', "searchable": false, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "Id", field: "Id", headerName: "#",  width: 80, filterType: "integer" },
                { colId: "UserName", field: "UserName", headerName: "User",  width: 200 },
                { colId: "ClientName", field: "ClientName", headerName: "Client",  width: 200 },
                { colId: "StationName", field: "StationName", headerName: "Station",  width: 200 },
                { colId: "From", field: "From", headerName: "From",  width: 200 },
                { colId: "Subject", field: "Subject", headerName: "Subject",  width: 350 },
                { colId: "Type", field: "Type", headerName: "Contact type",  width: 200 },
                { colId: "Email", field: "Email", headerName: "To",  width: 200 },
                { colId: "Body", field: "Body", headerName: "Email", cellTemplate: "<a (click)='parent.showEmail(params.data)' class='mdi mdi-eye'></a>", width: 50 },
            ]
        },
        {
            headerName: 'Amazon SES',
            children: [
                { colId: "AmazonSESStatus", field: "AmazonSESStatus", headerName: "Status", width: 100, cellClassRules: { 'pink-alert': params => params.value === 'Bounce',} },
                { colId: "AmazonSESStatusUpdateDate", field: "AmazonSESStatusUpdateDate", headerName: "Status updated on",  width: 200, filterType: "date", valueFormatter: this.formatters.utcDateTimeFormatter() },
            ]
        }
    ];

    showEmail(data) {
        const bodyIndex = data.Body?.lastIndexOf("<body>");
        const body = bodyIndex > -1 ? (data.Body?.substring(bodyIndex + 6, data.Body.lastIndexOf("</body>"))) : data.Body;

        this.dialog.open(StationDataRawDataDialogComponent, {
            data: {
                html: body,
            },
            width: '1030px',
            height: '680px',
            
        })
    }
}
