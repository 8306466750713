import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import 'moment-timezone';
import { ApiService } from '../shared/Api.service';
import { DialogService, GridComponent, ValueFormatters, SwxModule, GridModule } from 'swx.front-end-lib';
import {FlapContaminationAlertDialogComponent} from "./FlapContaminationAlertDialog.component";
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {Dialog} from "@angular/cdk/dialog";

@Component({
    templateUrl: 'FlapContaminationAlertList.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgFor,
        GridModule,
    ],
})
export class FlapContaminationAlertListComponent {
    @ViewChild('grid', { static: true }) grid: GridComponent;
    search;
    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    datePickerOptions: any;
    query: any;
    columnDefs = [
        {
            headerName: "",
            children: [
                { colId: "Id", field: "Id", headerName: "#", width: 90, pinned: 'left' },
                { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left' },
                { colId: "CreatedTime", field: "CreatedDate", headerName: "Created time", width: 70, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left' },
                { colId: "Type", field: "Type", headerName: "Type", width: 80, pinned: 'left' },
                { colId: "ConditionsReachedDate", field: "ConditionsReachedDate", headerName: "Conditions reached time", width: 100, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "AcarsInitTime", field: "AcarsInitTime", headerName: "ACARS INIT time", width: 100, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "ActualTakeOffTime", field: "ActualTakeOffTime", headerName: "Actual takeoff time", width: 100, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "EstimatedTouchdownTime", field: "EstimatedTouchdownTime", headerName: "Estimated touchdown time", width: 100, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "ActualTouchdownTime", field: "ActualTouchdownTime", headerName: "Actual touchdown time", width: 100, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "ClientName", field: "ClientName", headerName: "Client", width: 140, pinned: 'left' },
                { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 80, pinned: 'left' },
                { colId: "EquipmentTypeIdentifier", field: "EquipmentTypeIdentifier", headerName: "Equipment type", width: 140 },
                { colId: "RegistrationNumber", field: "RegistrationNumber", headerName: "Registration number", width: 140 },
                { colId: "FlightNumber", field: "FlightNumber", headerName: "Flight number", width: 140 },
                { colId: "FlapContaminationAlertProfileName", field: "FlapContaminationAlertProfileName", headerName: "Profile", width: 140 },
                { colId: "Message", field: "Message", headerName: "Message", cellTemplate: "<center><a (click)='parent.showMessage(params.data)' class='mdi mdi-eye'></a></center>", width: 100 },
            ]
        },
        {
            headerName: "Weather profile",
            children: [
                { colId: "StationName", field: "StationName", headerName: "Station", width: 100 },
                { colId: "WeatherProfile.DateTime", field: "WeatherProfile.DateTime", headerName: "Time", width: 60, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "WeatherProfile.LWE", field: "WeatherProfile.LWE", headerName: "LWE (g/dm²/h)", width: 100, filterType: "float", },
                { colId: "WeatherProfile.WeatherType", field: "WeatherProfile.WeatherType", headerName: "Weather type", width: 100, filterType: "enum", source: "ClientWeatherType" },
                { colId: "WeatherProfile.Temperature", field: "WeatherProfile.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float", },
                { colId: "WeatherProfile.RelativeHumidity", field: "WeatherProfile.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                { colId: "WeatherProfile.DewPoint", field: "WeatherProfile.DewPoint", headerName: "Dew point (°C)", width: 100, filterType: "float" },
                { colId: "WeatherProfile.ConeSurfaceTemperature", field: "WeatherProfile.ConeSurfaceTemperature", headerName: "Active Frost temp (°C)", width: 100, filterType: "float" },
                { colId: "WeatherProfile.FrostPoint", field: "WeatherProfile.FrostPoint", headerName: "Frost point (°C)", width: 100, filterType: "float" },
                { colId: "WeatherProfile.WindSpeed", field: "WeatherProfile.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float", },
                { colId: "WeatherProfile.Visibility", field: "WeatherProfile.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float", },
            ]
        },
        {
            headerName: 'Metar reading',
            children: [
                { colId: "MetarReading.Timestamp", field: "MetarReading.Timestamp", headerName: "Time", width: 60, filterType: "date", valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "MetarReading.WxString", field: "MetarReading.WxString", headerName: "Weather string", },
                { colId: "MetarReadingFinalWeatherType", field: "MetarReadingFinalWeatherType", headerName: "Final weather Type", width: 100, filterType: "enum", source: "MetarWeatherType" },
                { colId: "MetarReading.TempC", field: "MetarReading.TempC", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                { colId: "MetarReading.DewPointC", field: "MetarReading.DewPointC", headerName: "Dew point (°C)", width: 100, filterType: "float" },
                { colId: "MetarReadingDewPointDifference", field: "MetarReadingDewPointDifference", headerName: "T - Dew point (°C)", width: 100, filterType: "float" },
                { colId: "MetarReading.FrostPointC", field: "MetarReading.FrostPointC", headerName: "Frost point (°C)", width: 100, filterType: "float" },
                { colId: "MetarReading.RelativeHumidity", field: "MetarReading.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                { colId: "MetarReading.IsClearSky", field: "MetarReading.IsClearSky", headerName: "Clear sky?", width: 100, filterType: "integer" },
                { colId: "MetarReading.IsActiveFrost", field: "MetarReading.IsActiveFrost", headerName: "Active frost?", width: 100, filterType: "integer" },
                { colId: "MetarReadingVisibilityM", field: "MetarReadingVisibilityM", headerName: "Visibility (m)", width: 100, filterType: "float" },
                { colId: "MetarReadingWindSpeedMS", field: "MetarReadingWindSpeedMS", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
            ],
        },
    ];

    constructor(
        public api: ApiService,
        private viewContainerRef: ViewContainerRef,
        private formatters: ValueFormatters,
        private dialog: Dialog
    ) {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        if (window.localStorage['flapContaminationAlertQuery']) {
            this.query = JSON.parse(window.localStorage['flapContaminationAlertQuery']);
        } else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            ClientId: [],
            AirportId: [],
            FromDate: null,
            ToDate: null,
            Filters: []
        };
    };

    apply() {
        window.localStorage['flapContaminationAlertQuery'] = JSON.stringify(this.query);
        this.grid.refresh();
    }

    showMessage(data) {
        this.dialog.open(FlapContaminationAlertDialogComponent, {
            data: {
                message: data.Message,
            },
            width: '260px',
            height: '280px',
        })
    }
}
