import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import { DialogService, IAgGridColGroupDef, NotificationService, StorageService, TranslatePipe, ValueFormatters, SwxModule, GridModule } from "swx.front-end-lib";
import {StationDataRawDataDialogComponent} from "../stationDataGen2/StationDataRawDataDialog.component";
import moment from 'moment';
import {RootScope} from "../shared/RootScope.service";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import { NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    templateUrl: './WeatherAlert.component.html',
    standalone: true,
    imports: [SwxModule, FormsModule, NgFor, GridModule]
})
export class WeatherAlertComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'WeatherAlertQuery';
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    weatherAlertProfiles = this.api.WeatherAlertProfile.query();
    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.WeatherAlert;
    exportResourcePost = this.api.WeatherAlertExport;

    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private storage: StorageService,
                private formatters: ValueFormatters,
                private dialog: Dialog,
                private rootScope: RootScope,
                private ticketTrackingSystem: TicketTrackingSystemService,
                private translate: TranslatePipe,
                private notification: NotificationService) {
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery) {
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    }

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }


    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: 'Alerts',
            children: [
                { colId: "Id", field: "Id", headerName: "#", width: 90, pinned: 'left' },
                { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 100, pinned: 'left', valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "CreatedTime", field: "CreatedDate", headerName: "Created time", width: 80, excelIgnore: true, pinned: 'left', valueFormatter: this.formatters.utcTimeLongFormatter() },
                { colId: "ClientName", field: "ClientName", headerName: "Client", width: 140, pinned: 'left' },
                { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 80, pinned: 'left' },
                { colId: "WeatherAlertType", field: "WeatherAlertType", headerName: "Type", width: 80 },
                { colId: "WeatherAlertProfileName", field: "WeatherAlertProfileName", headerName: "Profile", width: 150 },
                { colId: "WeatherAlertLevelName", field: "WeatherAlertLevelName", headerName: "Level", width: 150 },
                { colId: "DataSource", field: "DataSource", headerName: "Source", width: 100 },
                { colId: "LastUpdateDate", field: "LastUpdate", headerName: "Last update date", width: 100, valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "LastUpdateTime", field: "LastUpdate", headerName: "Last update time", width: 80, excelIgnore: true, valueFormatter: this.formatters.utcTimeLongFormatter() },
                { colId: "LastUpdateDataSource", field: "LastUpdateDataSource", headerName: "Last update dource", width: 100 },
            ]
        },
        {
            headerName: 'Emails',
            children: [
                { colId: "Body", field: "Body", headerName: "Email", cellTemplate: "<a *ngIf='params.data?.Body' (click)='parent.showEmail(params.data)' class='mdi mdi-eye'></a>", width: 50 },
                { colId: "Emails", field: "Emails", headerName: "Recipients", minWidth: 300, searchable: false, valueFormatter: (params) => this.formatRecipients(params.value) },
            ]
        },
        {
            headerName: "Weather profile",
            children: [
                { colId: "WeatherProfile.LWE", field: "WeatherProfile.LWE", headerName: "LWE (g/dm²/h)", width: 100, filterType: "float", },
                { colId: "WeatherProfile.WeatherType", field: "WeatherProfile.WeatherType", headerName: "Weather type", width: 100, filterType: "enum", source: "ClientWeatherType" },
                { colId: "WeatherProfile.Temperature", field: "WeatherProfile.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float", },
                { colId: "WeatherProfile.RelativeHumidity", field: "WeatherProfile.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                { colId: "WeatherProfile.DewPoint", field: "WeatherProfile.DewPoint", headerName: "Dew point (°C)", width: 100, filterType: "float" },
                { colId: "WeatherProfileDewPointDifference", field: "WeatherProfileDewPointDifference", headerName: "T - Dew point (°C)", width: 100, filterType: "float" },
                { colId: "WeatherProfile.ConeSurfaceTemperature", field: "WeatherProfile.ConeSurfaceTemperature", headerName: "Active Frost temp (°C)", width: 100, filterType: "float" },
                { colId: "WeatherProfile.FrostPoint", field: "WeatherProfile.FrostPoint", headerName: "Frost point (°C)", width: 100, filterType: "float" },
                { colId: "WeatherProfileFrostPointDifference", field: "WeatherProfileFrostPointDifference", headerName: "Frost point T - Active Frost temp (°C)", width: 100, filterType: "float" },
                { colId: "WeatherProfile.WindSpeed", field: "WeatherProfile.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float", },
                { colId: "WeatherProfile.Visibility", field: "WeatherProfile.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float", },
            ]
        },
        {
            headerName: 'Metar reading',
            children: [
                { colId: "MetarReadingAgeMinutes", field: "MetarReadingAgeMinutes", headerName: "Age (minutes)", width: 100, filterType: "float" },
                { colId: "MetarReading.WxString", field: "MetarReading.WxString", headerName: "Weather string", },
                { colId: "MetarReadingFinalWeatherType", field: "MetarReadingFinalWeatherType", headerName: "Final weather Type", width: 100, filterType: "enum", source: "MetarWeatherType" },
                { colId: "MetarReading.TempC", field: "MetarReading.TempC", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                { colId: "MetarReading.DewPointC", field: "MetarReading.DewPointC", headerName: "Dew point (°C)", width: 100, filterType: "float" },
                { colId: "MetarReadingDewPointDifference", field: "MetarReadingDewPointDifference", headerName: "T - Dew point (°C)", width: 100, filterType: "float" },
                { colId: "MetarReading.FrostPointC", field: "MetarReading.FrostPointC", headerName: "Frost point (°C)", width: 100, filterType: "float" },
                { colId: "MetarReading.RelativeHumidity", field: "MetarReading.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                { colId: "MetarReading.IsClearSky", field: "MetarReading.IsClearSky", headerName: "Clear sky?", width: 100, filterType: "integer" },
                { colId: "MetarReading.IsActiveFrost", field: "MetarReading.IsActiveFrost", headerName: "Active frost?", width: 100, filterType: "integer" },
                { colId: "MetarReadingVisibilityM", field: "MetarReadingVisibilityM", headerName: "Visibility (m)", width: 100, filterType: "float" },
                { colId: "MetarReadingWindSpeedMS", field: "MetarReadingWindSpeedMS", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
            ],
        },
        {
            headerName: "Last update weather profile",
            children: [
                { colId: "LastUpdateWeatherProfile.LWE", field: "LastUpdateWeatherProfile.LWE", headerName: "LWE (g/dm²/h)", width: 100, filterType: "float", },
                { colId: "LastUpdateWeatherProfile.WeatherType", field: "LastUpdateWeatherProfile.WeatherType", headerName: "Weather type", width: 100, filterType: "enum", source: "ClientWeatherType" },
                { colId: "LastUpdateWeatherProfile.Temperature", field: "LastUpdateWeatherProfile.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float", },
                { colId: "LastUpdateWeatherProfile.RelativeHumidity", field: "LastUpdateWeatherProfile.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                { colId: "LastUpdateWeatherProfile.DewPoint", field: "LastUpdateWeatherProfile.DewPoint", headerName: "Dew point (°C)", width: 100, filterType: "float" },
                { colId: "LastUpdateWeatherProfileDewPointDifference", field: "LastUpdateWeatherProfileDewPointDifference", headerName: "T - Dew point (°C)", width: 100, filterType: "float" },
                { colId: "LastUpdateWeatherProfile.ConeSurfaceTemperature", field: "LastUpdateWeatherProfile.ConeSurfaceTemperature", headerName: "Active Frost temp (°C)", width: 100, filterType: "float" },
                { colId: "LastUpdateWeatherProfile.FrostPoint", field: "LastUpdateWeatherProfile.FrostPoint", headerName: "Frost point (°C)", width: 100, filterType: "float" },
                { colId: "LastUpdateWeatherProfileFrostPointDifference", field: "LastUpdateWeatherProfileFrostPointDifference", headerName: "Frost point T - Active Frost temp (°C)", width: 100, filterType: "float" },
                { colId: "LastUpdateWeatherProfile.WindSpeed", field: "LastUpdateWeatherProfile.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float", },
                { colId: "LastUpdateWeatherProfile.Visibility", field: "LastUpdateWeatherProfile.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float", },
            ]
        },
        {
            headerName: 'Last update metar reading',
            children: [
                { colId: "LastUpdateMetarReadingAgeMinutes", field: "LastUpdateMetarReadingAgeMinutes", headerName: "Age (minutes)", width: 100, filterType: "float" },
                { colId: "LastUpdateMetarReading.WxString", field: "LastUpdateMetarReading.WxString", headerName: "Weather string", },
                { colId: "LastUpdateMetarReadingFinalWeatherType", field: "LastUpdateMetarReadingFinalWeatherType", headerName: "Final weather Type", width: 100, filterType: "enum", source: "MetarWeatherType" },
                { colId: "LastUpdateMetarReading.TempC", field: "LastUpdateMetarReading.TempC", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                { colId: "LastUpdateMetarReading.DewPointC", field: "LastUpdateMetarReading.DewPointC", headerName: "Dew point (°C)", width: 100, filterType: "float" },
                { colId: "LastUpdateMetarReadingDewPointDifference", field: "LastUpdateMetarReadingDewPointDifference", headerName: "T - Dew point (°C)", width: 100, filterType: "float" },
                { colId: "LastUpdateMetarReading.FrostPointC", field: "LastUpdateMetarReading.FrostPointC", headerName: "Frost point (°C)", width: 100, filterType: "float" },
                { colId: "LastUpdateMetarReading.RelativeHumidity", field: "LastUpdateMetarReading.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                { colId: "LastUpdateMetarReading.IsClearSky", field: "LastUpdateMetarReading.IsClearSky", headerName: "Clear sky?", width: 100, filterType: "integer" },
                { colId: "LastUpdateMetarReading.IsActiveFrost", field: "LastUpdateMetarReading.IsActiveFrost", headerName: "Active frost?", width: 100, filterType: "integer" },
                { colId: "LastUpdateMetarReadingVisibilityM", field: "LastUpdateMetarReadingVisibilityM", headerName: "Visibility (m)", width: 100, filterType: "float" },
                { colId: "LastUpdateMetarReadingWindSpeedMS", field: "LastUpdateMetarReadingWindSpeedMS", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
            ],
        },
    ];
    
    showEmail(data) {
        var bodyIndex = data.Body?.lastIndexOf("<body>");
        const body = bodyIndex > -1 ? (data.Body?.substring(bodyIndex + 6, data.Body.lastIndexOf("</body>"))) : data.Body;

        const recipients = "<table class=\"table\">"
            + "<tr><th>Recipient</th><th>Status</th><th>Status updated on</th></tr>"
            + data.Emails.map(e => "<tr><td>" + e.Name + " &lt;" + e.Email + "&gt;</td><td style=\"" + (e.AmazonSESStatus === 'Bounce' ? 'background-color: #ffaaaa;' : '') + "\">" + (e.AmazonSESStatus || '') + "</td><td>" + (e.AmazonSESStatusUpdateDate ? moment.utc(e.AmazonSESStatusUpdateDate).format('YYYY-MM-DD HH:mm') : "") + "</td></tr>").join('')
            + "</table><br />";

        this.dialog.open(StationDataRawDataDialogComponent, {
            data: {
                html: recipients + body,
                title: data.Subject,
            },
            width: '1030px',
            height: '680px',
        })
    }

    formatRecipients(emails) {
        return emails == null ? '' : emails.map(e => e.Name + " <" + e.Email + ">").join(', ');
    }


    manualExitClick() {
        if (typeof this.query.ClientId === 'undefined'
            || this.query.ClientId.length !== 1
            || (typeof this.query.AirportId === 'undefined')
            || this.query.AirportId.length !== 1) {
            alert("Please select one client and one airport");
            return;
        }
        
        if (this.rootScope.currentUser.TicketTrackingRequired) {
            this.ticketTrackingSystem.getComment(this.viewContainerRef)
                .then(result => {
                    this.manualExit({...result, ...this.query});
                });
        } else {
            this.manualExit(this.query);
        }
    }
    
    manualExit(query) {
        this.api.WeatherAlertManualReset.post(query).then(result => {
                if (result.Message) {
                    alert(this.translate.transform(result.Message));
                } else {
                    this.notification.show(this.translate.transform('Weather alert monitoring has been reset for this client-airport. If the last email was an entry, then an exit email has been sent.'));
                }
            });
    }
} 
